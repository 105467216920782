import {
  createSettingsParam,
  createSettingsParams,
  SettingsParamType,
} from '@wix/tpa-settings';
import {
  AlignmentOptions,
  ServiceColor,
  LayoutOptions,
  SlotsAvailability,
  SourceOptions,
} from '../../types/types';
import { AccessibilityHtmlTags } from '../../utils/accessibility/constants';
import { InitializeCalendarDateOptions } from '../../utils/bi/consts';
import { timeSlotLayouts } from '../../utils/layouts';

export type ISettingsParams = {
  columnAlignment: SettingsParamType.String;
  textAlignment: AlignmentOptions;
  slotAlignment: AlignmentOptions;
  calendarLayout: LayoutOptions;
  selectedLocations: SettingsParamType.Text[];
  selectedCategories: SettingsParamType.Text[];
  dateAndTimeSectionHeader: SettingsParamType.String;
  bookingDetailsSectionHeader: SettingsParamType.String;
  headerTitleVisibility: SettingsParamType.Boolean;
  headerSubtitleVisibility: SettingsParamType.Boolean;
  headerFiltersVisibility: SettingsParamType.Boolean;
  headerServiceFilterVisibility: SettingsParamType.Boolean;
  headerStaffFilterVisibility: SettingsParamType.Boolean;
  headerLocationFilterVisibility: SettingsParamType.Boolean;
  headerTimezoneSelectionVisibility: SettingsParamType.Boolean;
  headerSubtitleSource: SourceOptions;
  headerTitle: SettingsParamType.String;
  headerTitleHtmlTag: SettingsParamType.String;
  headerSubtitle: SettingsParamType.String;
  noSessionsOffered: SettingsParamType.String;
  noUpcomingTimeSlots: SettingsParamType.String;
  goToNextAvailableDate: SettingsParamType.String;
  fullyBookedDateNotification: SettingsParamType.String;
  loadMoreTimeSlots: SettingsParamType.String;
  limitTimeSlotsDisplay: SettingsParamType.Boolean;
  maxTimeSlotsDisplayedPerDay: SettingsParamType.Number;
  videoConferenceBadgeVisibility: SettingsParamType.Boolean;
  videoConferenceBadge: SettingsParamType.String;
  preferencesTitle: SettingsParamType.String;
  serviceLabel: SettingsParamType.String;
  staffMemberLabel: SettingsParamType.String;
  locationLabel: SettingsParamType.String;
  durationLabel: SettingsParamType.String;
  bookingDetailsPricingPlanText: SettingsParamType.String;
  bookingDetailsClearText: SettingsParamType.String;
  nextButton: SettingsParamType.String;
  noSpotsLeft: SettingsParamType.String;
  toEarlyToBookIndication: SettingsParamType.String;
  toLateToBookIndication: SettingsParamType.String;
  pendingApprovalButton: SettingsParamType.String;
  joinWaitlistButton: SettingsParamType.String;
  rescheduleButton: SettingsParamType.String;
  slotsAvailability: SlotsAvailability;
  initializeCalendarDate: InitializeCalendarDateOptions;
  waitlistIndication: SettingsParamType.String;
  buttonsFullWidth: SettingsParamType.Boolean;
  slotLocationVisibility: SettingsParamType.Boolean;
  slotDurationVisibility: SettingsParamType.Boolean;
  slotStaffMemberVisibility: SettingsParamType.Boolean;
  slotPriceVisibility: SettingsParamType.Boolean;
  spotsLeftVisibility: SettingsParamType.Boolean;
  slotRegistrationStatusVisibility: SettingsParamType.Boolean;
  spotsLeftFormat: SettingsParamType.String;
  ctaVisibility: SettingsParamType.Boolean;
  useColorPerService: SettingsParamType.Boolean;
  servicesColorsType: ServiceColor;
};

const calendarLayout = createSettingsParam('calendarLayout', {
  getDefaultValue: ({ isMobile, experiments }) =>
    experiments.enabled('specs.bookings.calendarMobileImprovements') && isMobile
      ? LayoutOptions.DAILY_TIME_SLOTS_WEEKLY_PICKER
      : LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER,
  inheritDesktop: false,
});

const headerFiltersVisibility = createSettingsParam('headerFiltersVisibility', {
  type: SettingsParamType.Boolean,
  getDefaultValue: () => true,
});

export default createSettingsParams<ISettingsParams>({
  columnAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  textAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  slotAlignment: {
    inheritDesktop: false,
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  calendarLayout: calendarLayout as any,
  selectedLocations: {
    type: SettingsParamType.Object,
    getDefaultValue: () => [],
  },
  selectedCategories: {
    type: SettingsParamType.Object,
    getDefaultValue: () => [],
  },
  dateAndTimeSectionHeader: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  bookingDetailsSectionHeader: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  headerTitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerTitleHtmlTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => AccessibilityHtmlTags.MainHeading,
  },
  headerSubtitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerFiltersVisibility,
  headerServiceFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerStaffFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }) =>
      getSettingParamValue(headerFiltersVisibility) ?? true,
  },
  headerLocationFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }) =>
      getSettingParamValue(headerFiltersVisibility) ?? true,
  },
  headerTimezoneSelectionVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ isMobile, getSettingParamValue }) => {
      const layout =
        getSettingParamValue(calendarLayout) ||
        LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;

      return timeSlotLayouts.includes(layout) ? isMobile : false;
    },
  },
  headerSubtitleSource: {
    getDefaultValue: () => SourceOptions.CUSTOM,
  },
  headerTitle: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  headerSubtitle: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  noSessionsOffered: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  noUpcomingTimeSlots: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  goToNextAvailableDate: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  fullyBookedDateNotification: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  loadMoreTimeSlots: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  limitTimeSlotsDisplay: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  maxTimeSlotsDisplayedPerDay: {
    type: SettingsParamType.Number,
    getDefaultValue: ({ getSettingParamValue }) => {
      const layout =
        getSettingParamValue(calendarLayout) ||
        LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;
      return layout === LayoutOptions.WEEKLY_TIME_SLOTS ? 5 : 10;
    },
  },
  preferencesTitle: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  serviceLabel: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  staffMemberLabel: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  locationLabel: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  durationLabel: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  bookingDetailsPricingPlanText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pricing-plan.title'),
  },
  bookingDetailsClearText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.clear.title'),
  },
  nextButton: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  toEarlyToBookIndication: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  noSpotsLeft: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  toLateToBookIndication: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  rescheduleButton: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  pendingApprovalButton: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  joinWaitlistButton: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  videoConferenceBadgeVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  videoConferenceBadge: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  slotsAvailability: {
    getDefaultValue: () => SlotsAvailability.ALL,
  },
  initializeCalendarDate: {
    getDefaultValue: () => InitializeCalendarDateOptions.TODAY,
  },
  waitlistIndication: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  buttonsFullWidth: {
    type: SettingsParamType.Boolean,
    inheritDesktop: false,
    getDefaultValue: () => true,
  },
  slotLocationVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  slotRegistrationStatusVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  spotsLeftVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotPriceVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  slotStaffMemberVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotDurationVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  spotsLeftFormat: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  ctaVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  useColorPerService: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
    inheritDesktop: false,
  },
  servicesColorsType: {
    getDefaultValue: () => ServiceColor.BACKGROUND,
  },
});
